<template>
    <el-dialog
        :visible.sync="show"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="24">
                    <span class="title-name">{{ info.title }}</span>
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="0">
            <el-col :span="24">
                <el-form ref="ruleForm" label-width="110px" class="allForm">
                    <el-form-item label="线路名称" :required="true">
                        <el-input
                            v-model="form.lineName"
                            class="formInput"
                        />
                    </el-form-item>
					<el-form-item
					    label="公司名称"
					    :required="true"
					    class="formInput"
					>
					    <el-select
					        v-model="form.companyId"
					        class="w-100"
							filterable
					    >
					        <el-option
					           v-for="(v, i) in company"
					           :key="i"
					           :value="v.id"
					           :label="v.companyName"
					        />
					    </el-select>
					</el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="commit">提交</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
	import {
		company,
	} from '@/api'
export default {
    name: "Operate",
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                companyId: "",
                lineName: "",
            },
            company: [],
        };
    },
    created() {
		this.getCompany();
	},
    mounted() {
        this.$nextTick(() => {
            this.upd();
        });
    },
    methods: {
		getCompany() {
			this.$axiosReq(company, null, {
				pageSize: 10000
			}, 'get').then(res => {
				this.company = res.data.content
				if (this.company && this.company.length > 0 && this.info.addOr != "upd") {
					this.form.companyId = this.company[0].id;
				}
			})
		},
        upd() {
            if (this.info.addOr === "upd") {
                this.$axiosReq(
                    "/car/server/escort/web/line/" + this.row.id,
                    null,
                    null,
                    "get"
                ).then((res) => {
                    this.form.companyId = res.data.companyId;
                    this.form.lineName = res.data.lineName;
                });
            }
        },
        // 提交添加
        commit() {
            if (!this.form.lineName) {
                this.$message.error("请输入线路名称！");
                return;
            } else if (!this.form.companyId) {
                this.$message.error("请选择公司名称！");
                return;
            }
            if (this.info.addOr === "add") {
                this.form.sonIds = this.sonIds;
                this.$axiosReq('/car/server/escort/web/line', this.form, null, "post").then((res) => {
                    this.$message.success(res.msg);
                    this.close();
                    this.$emit("get-list");
                });
            } else {
                this.$axiosReq(
                    "/car/server/escort/web/line/" + this.row.id,
                    this.form,
                    null,
                    "put"
                ).then((res) => {
                    this.$message.success(res.msg);
                    this.close();
                    this.$emit("get-list");
                });
            }
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped>
.companyNameList {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.companyNameAllList {
    width: 260px;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    border: 1px solid #d9d8d3;
    padding: 5px;
    margin-right: 20px;
}

.companyNameAllList::-webkit-scrollbar {
    display: none;
}

.companyNameAllListNow {
    width: 260px;
    height: 160px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    border: 1px solid #d9d8d3;
    padding: 5px;
    margin-right: 20px;
}

.companyNameAllListNow::-webkit-scrollbar {
    display: none;
}

</style>
