<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<Permission v-if="dialog.refreshP" :dialog.sync="dialog.showP" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex" justify="space-between" :gutter="50">
				<el-col>
					<span class="text-primary text-pad-right">公司</span>
					<el-select v-model="condition.companyId" class="input searchInput" filterable clearable size="mini" @change="changeCondition">
						<el-option v-for="(v, i) in company" :key="i" :value="v.id" :label="v.companyName" />
					</el-select>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:org:line:management:add'
                                            )
                                        "
								 type="primary" size="mini" @click="
                                        openDialog({
                                            title: '添加线路',
                                            addOr: 'add',
                                        })
                                    ">添加线路</el-button>
								<!--                <el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>-->
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="600" row-id="id" :sort-config="{ remote: true }" :filter-config="{ remote: true }"
				 :checkbox-config="{ reserve: true }" :data="table.list" :loading="loading">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="companyName" title="公司名称" />
					<vxe-table-column field="lineName" title="线路名称" />
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="
                                            $hasBtn(
                                                'sys:permissions:org:line:management:edit'
                                            )
                                        "
									 icon="el-icon-edit" @click.native="
                                            openDialog(
                                                {
                                                    title: '修改线路',
                                                    addOr: 'upd',
                                                },
                                                row
                                            )
                                        ">修改</el-dropdown-item>
									<el-dropdown-item v-if="
                                            $hasBtn(
                                                'sys:permissions:org:line:management:delete'
                                            )
                                        " icon="el-icon-delete" @click.native="
                                            remove({ del: 'single' }, row)
                                        ">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from "@/utils/tableStyleJs";
	import {
		company,
		companyStatus,
		getAreaChildren,
		getDictParentCode,
		getAreaTreeByCode,
		areaParentCode,
	} from "@/api";
	import Operate from "./Operate";
	import Permission from "./Permission";

	export default {
		name: "Index",
		components: {
			Operate,
			Permission,
		},
		data() {
			return {
				loading: false,
				condition: {
					companyId: "",
					currentPage: 1,
					pageSize: 10,
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: "",
					sortBy: "",
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refreshP: false, // 每次刷新DOM
					show: false,
					showP: false,
				},
				region: [],
				industry: [],
				company: [],
				loginInfo: {},
			};
		},
		created() {
			this.getCompanyType();
		},
		methods: {
			rowStyle,
			headerStyle,
			getCompanyType() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content
					if (this.company && this.company.length > 0) {
						this.condition.companyId = this.company[0].id;
					}
					this.getList();
				})
			},
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true;
				this.dialog.show = true;
				this.dialog.info = info;
				this.dialog.row = row;
			}, // 打开dialog
			changeCondition(v) {
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$axiosReq(
						'/car/server/escort/web/line',
						null, {
							companyId: this.condition.companyId,
							currentPage: this.table.currentPage,
							pageSize: this.table.pageSize,
						},
						"get"
					)
					.then((res) => {
						this.table.list = res.data.content;
						this.table.total = Number(res.data.totalElements);
					})
					.finally((e) => {
						this.loading = false;
					});
			},
			remove(info, row) {
				this.$confirm(
						"确定删除吗！！！",
						"提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						}
					)
					.then(() => {
						this.$axiosReq(
							"/car/server/escort/web/line//" + row.id,
							null,
							null,
							"delete"
						).then((res) => {
							this.$message.success(res.msg);
							this.$refs.table.clearCheckboxRow();
							this.$refs.table.clearCheckboxReserve();
							this.getList();
						});
					})
					.catch(() => {
						this.$message.info("取消了删除！！！");
					});
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.getList();
			},
			refreshDialog() {
				this.dialog.refresh = false;
				this.dialog.refreshP = false;
			},
		},
	};
</script>

<style scoped></style>
