<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item v-if="info.addOr==='permi'" label="权限" :required="true">
            <!--            <el-cascader-->
            <!--              v-model="form.permissionIds"-->
            <!--              :options="permission"-->
            <!--              filterable-->
            <!--              clearable-->
            <!--              class="formInput w-100"-->
            <!--              :props="{lazy:false,value:'id',children:'sons',-->
            <!--                       label:'permissionsName',checkStrictly:true,emitPath:false,-->
            <!--                       multiple: true}"-->
            <!--            />-->
            <el-tree
              ref="tree"
              :data="permission"
              show-checkbox
              node-key="id"
              :expand-on-click-node="false"
              :default-expand-all="expandTree"
              :props="{children:'sons',
                       label:'permissionsName'}"
            />
          </el-form-item>
          <el-form-item v-else-if="info.addOr==='posi'" label="岗位" :required="true">
            <el-select v-model="form.positionIds" class="formInput w-100" filterable multiple>
              <el-option v-for="(v,i) in positionList" :key="v.id" :value="v.id" :label="v.positionName" />
            </el-select>
          </el-form-item>
          <el-form-item v-else-if="info.addOr==='role'" label="角色" :required="true">
            <el-select v-model="form.roleIds" class="formInput w-100" filterable multiple>
              <el-option v-for="(v,i) in roleList" :key="v.id" :value="v.id" :label="v.roleName" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { permissions, companyPermission,
  companyPosition, companyRole, dropComPosition, roleComDrop, company, roleComEcho } from '@/api'
export default {
  name: 'Permission',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        permissionIds: [],
        positionIds: [],
        roleIds: []
      },
      permission: [],
      positionList: [],
      roleList: [],
      expandTree: true
    }
  },
  created() {
    this.getSelect()
  },
  mounted() {
    // setTimeout(this.getCompany,100)
    this.getCompany()
    if (this.$admin) {
      if (this.info.addOr === 'role') {
        this.getComRole()
      }
    }
  },
  methods: {
    getCompany() {
      this.$axiosReq(company + '/' + this.row.id, null, null, 'get').then(res => {
        if (this.$admin) {
          if (this.info.addOr === 'posi') {
            for (const i of res.data.positionVoList) {
              this.form.positionIds.push(i.id)
            }
          }
        }
        if (this.info.addOr === 'permi') {
          for (const i of res.data.permissionsVoList) {
            this.form.permissionIds.push(i.id)
          }
          this.$refs.tree.setCheckedKeys(this.form.permissionIds)
        }
      })
    },
    getComRole() {
      this.$axiosReq(roleComEcho, null, {
        companyId: this.row.id
      }, 'get').then(res => {
        if (res.data) {
          for (const i of res.data) {
            this.form.roleIds.push(i.id)
          }
        }
      })
    },
    getSelect() {
      if (this.$admin) {
        if (this.info.addOr === 'posi') {
          this.getPosition()
        } else if (this.info.addOr === 'role') {
          this.getRole()
        }
      }
      if (this.info.addOr === 'permi') {
        this.getPermission()
      }
    },
    getPermission() {
      this.$axiosReq(permissions, null, {
        search: '',
        statu: 0,
        type: 1,
        pageSize: 10000,
        companyId: this.$admin ? null : this.row.id
      }, 'get').then(res => {
        this.permission = res.data
      })
    },
    getPosition() {
      this.$axiosReq(dropComPosition, null, {
        companyId: this.row.id
      }, 'get').then(res => {
        this.positionList = res.data
      })
    },
    getRole() {
      this.$axiosReq(roleComDrop, null, {
        companyId: this.row.id
      }, 'get').then(res => {
        this.roleList = res.data
      })
    },
    // 提交添加
    commit() {
      if (this.info.addOr === 'permi') {
        this.form.permissionIds = this.$refs.tree.getCheckedKeys()
      } else if (this.info.addOr === 'posi') {
        if (this.form.positionIds.length === 0) {
          this.$message.error('请选择岗位！')
          return
        }
      } else if (this.info.addOr === 'role') {
        if (this.form.roleIds.length === 0) {
          this.$message.error('请选择角色！')
          return
        }
      }
      if (this.info.addOr === 'permi') {
        this.$axiosReq(companyPermission + this.row.id, this.form.permissionIds, null, 'put').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        })
      } else if (this.info.addOr === 'posi') {
        this.$axiosReq(companyPosition + this.row.id, this.form.positionIds, null, 'put').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        })
      } else if (this.info.addOr === 'role') {
        this.$axiosReq(companyRole + this.row.id, this.form.roleIds, null, 'put').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        })
      }
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
